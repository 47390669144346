/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use "@ft/core/style" as ft;
@use "@ft/file-manager/style" as ftVisualizer;
@import "styles/dark_theme";

// load styles
@include ft.load-theme();
@include ftVisualizer.load-style();

// dragula specific css
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

// global css
.ft-actions.refresh-action {
  z-index: 10;
  border: none;
  position: absolute;
  background: transparent;
  left: calc(100% - 68px);
  bottom: 16px;
}

[matripple], [mattooltip], [mattooltipposition] {
  touch-action: auto !important;
}

// to be moved to ftCore
// upload box
.progress-section, .upload-section {
  align-items: center;
  justify-content: center;

  margin: ft.$ft-medium-spacer;
  border-radius: ft.$ft-border-radius;
  background: mat.get-color-from-palette(ft.$ft-grey-palette, 50);
  width: calc(100% - #{ft.$ft-medium-spacer * 2});
  border: 2px mat.get-color-from-palette(ft.$ft-grey-palette, 200) dashed;

  span {
    font-size: 25px;
    font-weight: 500;
  }
}

.upload-section {
  cursor: pointer;
  transition: border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &.file-over {
    border-color: rgba(ft.$ft-primary-color, .8);

    span, .mat-icon {
      color: rgba(ft.$ft-primary-color, .8);
    }
  }

  span, .mat-icon {
    color: mat.get-color-from-palette(ft.$ft-grey-palette, 200);
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .mat-icon {
    font-size: 30px;
    margin: ft.$ft-medium-spacer 0 0 0;
  }
}

.progress-section {
  .mat-progress-bar {
    width: 60%;
  }

  span {
    font-size: 20px;
    color: ft.$ft-primary-color;
    margin: ft.$ft-medium-spacer 0 0 0;
  }
}
